<template>
  <component :is="jobData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="jobData === undefined">
      <h4 class="alert-heading">Erro ao carregar dados</h4>
      <div class="alert-body">
        Comunicado não encontrado
        <b-link class="alert-link" :to="{ name: 'apps-jobs-list' }">
          Lista de jobs
        </b-link>
        for other jobs.
      </div>
    </b-alert>

    <template>
      <div>
        <!-- Alert: No item found -->
        <b-alert variant="danger" :show="jobData === undefined">
          <h4 class="alert-heading">Erro ao carregar dados</h4>
          <div class="alert-body">
            Job não encontrado
            <b-link class="alert-link" :to="{ name: 'apps-jobs-list' }">
              Lista de jobs
            </b-link>
            for other jobs.
          </div>
        </b-alert>

        <template v-if="jobData">
          <div>
            <b-card title="Jobs">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Id" label-for="nameJobs">
                    <b-form-input
                      v-model="jobData.ID"
                      label="name"
                      label-for="nameJobs"
                      readonly
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group label="Descrição" label-for="nameJobs">
                    <b-form-input
                      v-model="jobData.DESCRIPTION"
                      label="name"
                      label-for="nameJobs"
                      readonly
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="CRON" label-for="nameJobs">
                    <b-form-input
                      v-model="jobData.CRON"
                      label="name"
                      label-for="nameJobs"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Ativo" label-for="nameJobs">
                    <b-form-input
                      v-model="jobData.ACTIVE"
                      label="name"
                      label-for="nameJobs"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Duração" label-for="nameJobs">
                    <b-form-input
                      v-model="jobData.DURATION"
                      label="name"
                      label-for="nameJobs"
                      readonly
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="5">
                  <b-form-group label="Última execução" label-for="membersJobs">
                    <b-form-input
                      id="created-date"
                      v-model="jobData.LAST_EXECUTION"
                      class="form-control"
                      disabled
                    />
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Execução" label-for="nameJobs">
                    <b-form-input
                      v-model="jobData.LAST_RESULT"
                      label="name"
                      label-for="nameJobs"
                      readonly
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group label="Bloqueado" label-for="nameJobs">
                    <b-form-input
                      v-model="jobData.BLOCK"
                      label="name"
                      label-for="nameJobs"
                      readonly
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col> </b-row
              ><br />
            </b-card>
          </div>
        </template>
      </div>
    </template>
    <div class="text-right">
      <b-button
        variant="primary"
        @click="updateJob()"
        :to="{ name: 'apps-jobs-list' }"
      >
        Salvar
      </b-button>
    </div>
  </component>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import vSelect from "vue-select";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
  },

  data() {
    return {
      userData: store.state.user.userData,
    };
  },

  methods: {
    keysToLowerCase(obj) {
      Object.keys(obj).forEach(function (key) {
        var k = key.toLowerCase();

        if (k !== key) {
          obj[k] = obj[key];
          delete obj[key];
        }
      });
      return obj;
    },

    async updateJob() {
      this.jobData = this.keysToLowerCase(this.jobData);

      await axios({
        method: "put",
        url: `${URL_API}job/update/${this.jobData.id}`,
        headers: getHeader(this.userData),
        data: this.jobData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: this.$i18n.t("message.toast.saved"),
            icon: "CheckIcon",
            variant: "success",
            text: this.$i18n.t("message.toast.updated"),
          },
        });
      });
    },
  },

  setup() {
    const jobData = ref(null);

    axios({
      method: "get",
      url: `${URL_API}job/${router.currentRoute.params.id}`,
      headers: getHeader(store.state.user.userData),
    })
      .then((response) => {
        jobData.value = response.data.body;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          jobData.value = undefined;
        }
      });

    return {
      jobData,
      vSelect,
    };
  },
};
</script>
